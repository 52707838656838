export const debounce=(fnc, delay)=>{
    let timer=null
    return function (...args) {
        if (timer){
            clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            fnc.apply(this,args)
        },delay)
    }
}

export const throttle=(fnc,delay)=>{
    let timer=null
    return function (...args){
        if (timer){
            return
        }
        timer=setTimeout(()=>{
            fnc.apply(this,args)
            timer=null
        },delay)
    }
}